import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import PRoute from "./utilities/privateroutes";
import NRoute from "./utilities/normalroutes";
import {
  setJWT,
  getLocalStorage,
  setLocalStorage,
  setUnAuthInterceptor,
  removeLocalStorage,
} from "./utilities/axios";
// styles
import "bootstrap/scss/bootstrap.scss";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/style.css";
import "leaflet/dist/leaflet.css";
// pages
const Home = React.lazy(() => import("views/Home/Home.js"));
const Login = React.lazy(() => import("views/Login/Login"));
const Register = React.lazy(() => import("views/Register/Register"));
const About = React.lazy(() => import("views/Aboutus/About"));
const Faq = React.lazy(() => import("views/Faq/Faq"));
const Booking = React.lazy(() => import("views/Booking/Booking"));
///////////
// const Profile = React.lazy(() => import("views/Profile/Profile"));
// others

const IndexApp = () => {
  const [isLogged, setisisLogged] = useState(false);
  const [isJwt, setisJwt] = useState(getLocalStorage("jwt") || "");
  useEffect(() => {
    if (isJwt !== "") {
      setJWT(isJwt);
      setisisLogged(true);
    }
    setUnAuthInterceptor(setLoggoutData);
  });

  const setLogginData = (jwt) => {
    setisJwt(jwt);
    setisisLogged(true);
    setLocalStorage("jwt", jwt);
    setJWT(jwt);
  };
  const setLoggoutData = () => {
    setisJwt("");
    setisisLogged(false);
    setJWT("");
    removeLocalStorage("jwt");
  };
  const auth = {
    isLogged: isLogged,
    login: setLogginData,
    logout: setLoggoutData,
  };

  return (
    <React.Suspense fallback={<div>...Loading</div>}>
      <BrowserRouter>
        <Switch>
          <NRoute path="/index" component={Home} exact auth={auth} />
          <NRoute path="/login" component={Login} exact auth={auth} />
          <NRoute path="/register" component={Register} exact auth={auth} />
          <NRoute path="/about" component={About} exact auth={auth} />
          <NRoute path="/faq" component={Faq} exact auth={auth} />
          <NRoute path="/booking" component={Booking} exact auth={auth} />
          {/* <PRoute path="/profile" component={Profile} exact auth={auth} /> */}

          <Redirect to="/index" />
        </Switch>
      </BrowserRouter>
    </React.Suspense>
  );
};

ReactDOM.render(<IndexApp />, document.getElementById("root"));
